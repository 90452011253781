import { MicroFrontend } from '@praxis/component-microfrontend'
import { Grid } from '@enterprise-ui/canvas-ui-react'

const mfeConfig = {
  // metricTest: 'veSearch@//localhost:3000/remoteEntry.js',
  metricTest: 'veSearch@//itemsearch-stg.prod.target.com/remoteEntry.js',
}

export const SearchHomeMFE = ({ ...rest }) => {
  return (
    <>
      <Grid.Container style={{ width: '98vw' }}>
        <Grid.Item xs={12} style={{ marginTop: '4em', marginLeft: '-4em' }}>
          <MicroFrontend
            module="./Search"
            src={mfeConfig.metricTest}
            anyProp={'any value'}
            {...rest}
          ></MicroFrontend>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
